

export const MESSAGE = 'MESSAGE';
export const MESSAGE5 = 'MESSAGE5';
export const MESSAGE2 = 'MESSAGE2';
export const MESSAGE1 = 'MESSAGE1';
export const MESSAGE3 = 'MESSAGE3';
export const MESSAGE4 = 'MESSAGE4';
export const LOGGEDIN = 'LOGGEDIN';
export const RIGHTS = 'RIGHTS';
export const ROLE = 'ROLE';
export const ADVERTISER = 'ADVERTISER';
export const PRODUCER = 'PRODUCER'
export const DISTRIBUTOR = 'DISTRIBUTOR';
export const LOADING_TOGGLE_ACTION = "LOADING_TOGGLE_ACTION";
export const TRANSLATE = "TRANSLATE"
// export const SUBADMIN = "SUBADMIN"
export const PROFILE = 'PROFILE';


//Products
export const PRODUCTS = "PRODUCTS"
export const PRODUCT = "PRODUCT"

//Buyers 
export const BUYERS = 'BUYERS'

// Masters
export const CATEGORIES = 'CATEGORIES'
export const SUBCATEGORIES= 'SUBCATEGORIES'
export const CASTS= 'CASTS'
export const GENRE= 'GENRE'
export const LANGUAGES= 'LANGUAGES'
export const DISTRIBUTORS= 'DISTRIBUTORS'
export const PRODUCERS= 'PRODUCERS'
export const SUBADMINS= 'SUBADMINS'
export const MODULES= 'MODULES'
export const AVATARS = "AVATARS"
export const COUNTRIES = "COUNTRIES"
export const ADVISORY = "ADVISORY"
export const UNITS = "UNITS"
export const PRICERANGE = 'PRICERANGE'
export const STORECATEGORY = 'STORECATEGORY'
export const GSTRATE = 'GSTRATE'
export const GALLARY = 'GALLARY'
export const GALLERY_CATEGORY_LIST = "GALLERY_CATEGORY_LIST"
export const APP_SETTING = "APP_SETTING"
// export const GALLERY_CATEGORY_LIST = "GALLERY_CATEGORY_LIST"

// Subscription
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS'
export const ADSUBSCRIPTIONS = 'ADSUBSCRIPTIONS'

// Post
export const POSTS = "POSTS"
export const POST = "POST"

//Reports
export const REPORTS = "REPORTS" 

//Bank Details
export const BANKDETAILS = "BANKDETAILS"
export const BANKDETAILCREATE = "BANKDETAILCREATE"
export const BANKDETAILSLIST = "BANKDETAILSLIST"

export const MESSAGEOTP = "MESSAGEOTP"
export const MESSAGEOTPVERIFY = "MESSAGEOTPVERIFY"

//Payment

export const RAZORPAYRES ="RAZORPAYRES"

// Advertisement
export const ADVERTISEMENTS = "ADVERTISEMENTS"
export const ADPAYMENT="ADPAYMENT"
export const ADPAY = "ADPAY"
export const SETAD = "SETAD"
export const ADDELETE = "ADDELETE"
export const ADVERTISEMENT = "ADVERTISEMENT"
export const ADMINADVERTISEMENT = "ADMINADVERTISEMENT"
export const ADTRANSACTIONS = "ADTRANSACTIONS"

// Customer
export const CUSTOMERS = "CUSTOMERS"
export const CUSTOMER = "CUSTOMER"
export const CUSTOMER_LIST = "CUSTOMER_LIST"
export const USERPOSTLIST = "USERPOSTLIST"
export const USERORDERLIST = "USERORDERLIST"
export const PREMIUMCUSBYPLAN = "PREMIUMCUSBYPLAN"
export const PREMIUMCUSBYRENT = "PREMIUMCUSBYRENT"

//Orders
export const ORDERS = 'ORDERS'
export const ORDER = 'ORDER'



// Live Streaming
export const TV_CATEGORIES = "TV_CATEGORIES"
export const TV_CHANNELS = "TV_CHANNELS"

//Current
export const CURRENTTIME = "CURRENTTIME" 

// Merchandise
export const COUPONS = "COUPONS"
export const COUPONHISTORY = "COUPONHISTORY"
export const NOTIFICATIONS = "NOTIFICATIONS"
export const NOTIFICATIONS_COUNT = "NOTIFICATIONS_COUNT"
export const NOTIFICATION_READ = "NOTIFICATION_READ"
export const NOTIFICATION_MASK = "NOTIFICATION_MASK"
export const SLIDER_BANNERS = "SLIDER_BANNERS"

// Movie
export const MOVIES = "MOVIES"
export const MOVIE = "MOVIE"
export const UPCOMING_MOVIES = "UPCOMING_MOVIES"
export const MOVIE_WATCH_USER = "MOVIE_WATCH_USER"
export const MOVIE123 = "MOVIE123"
export const SUBTITLEDELETE = "SUBTITLEDELETE"
export const AUDIOFILEDELETE = "AUDIOFILEDELETE"
export const MOVIENAMELIST = "MOVIENAMELIST"



// Report
export const PPV_CUSTOMERS = "PPV_CUSTOMERS"
export const REGISTERED_CUSTOMERS = "REGISTERED_CUSTOMERS"
export const SUBSCRIBED_CUSTOMERS = "SUBSCRIBED_CUSTOMERS"

// Transaction
export const TRANSACTIONS = "TRANSACTIONS"

// Watchhours
export const WATCHHOURS = "WATCHHOURS"

//Promotion

export const PROMOTION = "PROMOTION"

//Highlight

export const HIGHLIGHT = "HIGHLIGHT"

// Web Series
export const EPISODES = "EPISODES"
export const EPISODE = "EPISODE"
export const SERIES = "SERIES"
export const SEASONS = "SEASONS"
export const EPISODE_WATCH_USER = "EPISODE_WATCH_USER"
export const EPSUBTITLEDELETE = "EPSUBTITLEDELETE"
export const EPAUDIOFILEDELETE = "EPAUDIOFILEDELETE"
export const SERIESDETAILS = "SERIESDETAILS"
// Produce Form

export const PRODUCERFORMDATA = "PRODUCERFORMDATA"
export const PRODUCERFORMDETAILS = "PRODUCERFORMDETAILS"

// Setting
export const ABOUTUS = "ABOUTUS"
export const PRIVACYPOLICY = "PRIVACYPOLICY"
export const TERMSCONDITIONS = "TERMSCONDITIONS"
export const REFUNDPOLICY = "REFUNDPOLICY"
export const APPSETTING = "APPSETTING"
export const LOGS = "LOGS"
export const LOGSDEC = "LOGSDEC"

// Dashboard
export const TOTALUSERS = "TOTALUSERS"
export const DASHBOARDCOUNT = "DASHBOARDCOUNT"
export const DASHBOARDMOVIES = "DASHBOARDMOVIES"
export const TOTALSUBSCRIBEUSER = "TOTALSUBSCRIBEUSER"
export const MONTHLYREVENUE = "MONTHLYREVENUE"
export const TOTALREVENUE = "TOTALREVENUE"
export const TV_SHOW_DASHBOARD = "TV_SHOW_DASHBOARD"
export const RECENT_SUBSCRIBER_DASHBOARD = "RECENT_SUBSCRIBER_DASHBOARD"
export const DAILY_REVENUE = "DAILY_REVENUE"
export const MAX_LOGIN_DURATION = "MAX_LOGIN_DURATION"
export const RENEWAL = "RENEWAL"
export const PRODUCER_MOVIE_GRAPH = "PRODUCER_MOVIE_GRAPH"
export const ADVERTISEMENT_VIEW_GRAPH = "ADVERTISEMENT_VIEW_GRAPH"
export const PRODUCER_MOVIE_LIST = "PRODUCER_MOVIE_LIST"
export const REGION_CHART = "REGION_CHART"

export const HIGHESTMOVIECOUNT = "HIGHESTMOVIECOUNT"
export const TOTALREVENUEDIS = "TOTALREVENUEDIS"


// Complaint
export const COMPLAINTS = "COMPLAINTS"

//Advertisors
export const ADVERTISERS = "ADVERTISERS"
export const ADPAYMENTLIST = "ADPAYMENTLIST"
export const ADFORMDETAILS = "ADFORMDETAILS"

//Analytics
export const HIGHEST_WATCHED_MOVIES_GRAPH ="HIGHEST_WATCHED_MOVIES_GRAPH"
export const LOWEST_WATCHED_MOVIES = "LOWEST_WATCHED_MOVIES"
export const HIGHEST_SEARCHED_MOVIES_GRAPH = "HIGHEST_SEARCHED_MOVIES_GRAPH"
export const CURRENTLY_LOGGED_IN_USERS = "CURRENTLY_LOGGED_IN_USERS"
export const CURRENTLY_LOGGED_IN_USERS_BY_STATE_GRAPH = "CURRENTLY_LOGGED_IN_USERS_BY_STATE_GRAPH"
export const CURRENTLY_WATCHING_USERS = "CURRENTLY_WATCHING_USERS"
export const DEVICE_USED_FOR_WATCHING = "DEVICE_USED_FOR_WATCHING"
export const AREA_WISE_AD_VIEW = "AREA_WISE_AD_VIEW"





