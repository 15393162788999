import * as actionType from "../constants/actionTypes";

const mastersReducer = (state = { mastersData: null }, action) => {
	switch (action.type) {
		
		case actionType.CATEGORIES:
			return {...state,categories: action.payload};
			case actionType.SUBCATEGORIES:
			return {...state,subcategories: action.payload};
			case actionType.CASTS:
			return {...state,casts: action.payload};
			case actionType.COUNTRIES:
			return {...state,countries: action.payload};
			
			case actionType.GENRE:
			return {...state,genre: action.payload};

			case actionType.LANGUAGES:
			return {...state,languages: action.payload};
			case actionType.AVATARS:
			return { ...state, avatars: action.payload };
			case actionType.UNITS:
			return {...state,unit_list: action.payload};
			case actionType.PRICERANGE :
			return {...state , price_range :action.payload}
			case actionType.MODULES:
			return {...state,modules: action.payload};
			case actionType.SUBADMINS:
			return {...state,subadmins: action.payload};
			case actionType.ADVISORY:
				return {...state,advisory: action.payload};
			case actionType.MESSAGE5:
			return {...state,cast_msg: action.payload};
			case actionType.STORECATEGORY:
			return {...state,store_category: action.payload};
			case actionType.GSTRATE:
			return {...state,gst_rates: action.payload};
			case actionType.GALLARY:
			return {...state,gallary: action.payload};
			case actionType.GALLERY_CATEGORY_LIST:
			return {...state,gallery_list: action.payload};
			case actionType.APP_SETTING:
			return {...state,app_setting: action.payload};
			
		default:
			return state;
	}
};

export default mastersReducer;
